import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { bool, shape, string, object, arrayOf } from 'prop-types';
import styled from 'styled-components';
import { Page, LayoutComposer } from '../../components';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../util/routes';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { propTypes } from '../../util/types';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import TopbarSearchFormLocation from '../../components/Topbar/TopbarSearchForm/TopbarSearchFormLocation';
import NotFoundPage from '../../containers/NotFoundPage/NotFoundPage';

import HowItWorks from './HowItWorks';
import FAQ from './Faq';
import SimilarSearches from './SimilarSearches';
import LocationListings from './LocationListings';
import Sublocations from './Sublocations';
import ListingReviews from './ListingReviews';

import css from './SeoGlendale.module.css';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // text-align: center;
  color: black;
  padding: 2rem;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1200px) {
    max-width: 1000px;
  }

  @media (max-width: 1000px) {
    max-width: 800px;
  }

  @media (max-width: 800px) {
    max-width: 600px;
  }

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;

const Header = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
  line-height: 1.6;

  @media (max-width: 600px) {
    font-size: 2rem;
  }
`;

const Subheading = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;

  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
`;

const Button = styled.button`
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin: 1rem 0;

  @media (max-width: 600px) {
    padding: 0.5rem 0.8rem;
    font-size: 0.8rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const Text = styled.p`
  font-size: 1rem;

  @media (max-width: 600px) {
    font-size: 0.9rem;
  }
`;

function formatLocationName(cityName) {
  return cityName
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export const LocationPageComponent = props => {
  const {
    listings,
    reviews,
    sublocations,
    isLoading,
    error,
    locationNotFound,
    params,
    intl,
    scrollingDisabled,
  } = props;

  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const { location, category } = params;
  const locationFormatted = formatLocationName(location);

  const handleSubmit = values => {
    const { search, selectedPlace } = values?.location;
    const { origin, bounds } = selectedPlace;
    const searchParams = { ...(origin && { origin }), address: search, bounds };
    const url = createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams);
    window.location.href = url;
  };

  if (isLoading) return null;
  if (locationNotFound || category !== 'cakes') return <NotFoundPage />;

  const title = intl.formatMessage({ id: 'LocationPage.title' }, { location: locationFormatted });
  const description = intl.formatMessage(
    { id: 'LocationPage.description' },
    { location: locationFormatted }
  );

  return (
    <Page
      className={css.root}
      title={title}
      description={description}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'LocationShops',
        description: description,
        name: title,
      }}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutComposer
        areas={`
          topbar
          main
          footer
        `}
        className={css.layout}
      >
        {({ Topbar, Main, Footer }) => (
          <>
            <Topbar as="header" className={css.topbar}>
              <TopbarContainer />
            </Topbar>
            <Main className={css.mainwrapper}>
              <UnifiedView
                location={locationFormatted}
                handleSubmit={handleSubmit}
                config={config}
                listings={listings}
                reviews={reviews}
                sublocations={sublocations}
                category={category}
                intl={intl}
              />
            </Main>
            <Footer>
              <FooterContainer />
            </Footer>
          </>
        )}
      </LayoutComposer>
    </Page>
  );
};

const UnifiedView = ({
  location,
  handleSubmit,
  config,
  listings,
  reviews,
  sublocations,
  category,
  intl,
}) => (
  <Container>
    <div style={{ textAlign: 'center' }}>
      <Text>World's largest marketplace for local bakers/bakeries</Text>
      <Header>{location} Cakes</Header>
      <Text>Discover Thousands of Delectable Desserts from Local Bakers in {location}</Text>

      <div>
        <TopbarSearchFormLocation
          className={css.searchLink}
          desktopInputRoot={css.topbarSearchWithLeftPadding}
          onSubmit={handleSubmit}
          appConfig={config}
          isMobile={false}
          locationcity={location}
        />
        <Button>Order {category} nearby</Button>
      </div>
      <TextContainer>
        <Text>100% Guarantee</Text>
        <Text>Local Delivery</Text>
      </TextContainer>
    </div>
    <LocationListings location={location} notitle={true} latestListings={listings} />
    <HowItWorks />
    <ListingReviews reviews={reviews} />
    <FAQ location={location} />
    <Sublocations
      sublocations={sublocations}
      location={location}
      category={category}
      sublocation={''}
    />
  </Container>
);

LocationPageComponent.defaultProps = {
  listings: [],
  reviews: [],
  sublocations: [],
  isLoading: false,
  error: null,
  locationNotFound: false,
};

LocationPageComponent.propTypes = {
  params: shape({
    location: string.isRequired,
    category: string.isRequired,
  }).isRequired,
  listings: arrayOf(propTypes.listing),
  reviews: arrayOf(propTypes.review),
  sublocations: arrayOf(object),
  isLoading: bool,
  error: propTypes.error,
  locationNotFound: bool,
  intl: intlShape.isRequired,
  scrollingDisabled: bool.isRequired,
};

const mapStateToProps = state => ({
  listings: state.LocationPage.listings,
  reviews: state.LocationPage.reviews,
  sublocations: state.LocationPage.sublocations,
  isLoading: state.LocationPage.isLoading,
  error: state.LocationPage.error,
  locationNotFound: state.LocationPage.locationNotFound,
  scrollingDisabled: isScrollingDisabled(state),
});

const LocationPage = compose(connect(mapStateToProps), injectIntl)(LocationPageComponent);

export default LocationPage;
